import api from "../axios_service.js";

// Busca Todas os locais de Redes Sociais
export async function fetchLocaisRedesSociais(filtros) {
  const response = await api.get(
    `/colaborador/locais-redes-sociais${filtros ? filtros : ""}`
  );
  return response.data.result;
}

// Busca um local de Rede Social
export async function fetchLocalRedeSocial(id) {
  const response = await api.get(`/colaborador/locais-redes-sociais/${id}`);
  return response.data.result;
}

// Atualiza um local de Rede Social
export function putLocalRedeSocial(id, local) {
  let body = {};
  for (let key in local) {
    body[key] = local[key];
  }
  return api.put(`/colaborador/locais-redes-sociais/${id}`, body);
}

// Cria um novo local de Rede Social
export function postLocalRedeSocial(local) {
  let body = {};
  for (let key in local) {
    body[key] = local[key];
  }
  return api.post("/colaborador/locais-redes-sociais/add", body);
}
