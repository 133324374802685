var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"500"},on:{"click:outside":function($event){return _vm.$emit('update:dialogEditRedeSocial', false)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('update:dialogEditRedeSocial', false)}},model:{value:(_vm.dialogEditRedeSocial),callback:function ($$v) {_vm.dialogEditRedeSocial=$$v},expression:"dialogEditRedeSocial"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"pa-4 font-weight-bold accent"},[_vm._v(" "+_vm._s(_vm.add ? "Adicionar" : "Editar")+" Rede Social "),_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.$emit('update:dialogEditRedeSocial', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-card-text',{staticClass:"pt-5"},[_c('v-text-field',{staticClass:"mb-6",attrs:{"label":"Rede Social","dense":"","rules":_vm.formRules,"required":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","right":"","offset-x":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"pa-1 mb-2 mr-1",attrs:{"size":"30","tile":""}},'v-avatar',attrs,false),on),[(_vm.redeSocial.icone)?_c('v-img',{attrs:{"src":_vm.redeSocial.icone,"max-width":"30px","max-height":"30px"}}):_c('v-icon',[_vm._v("mdi-image-plus")])],1)]}}])},[_c('IconesRedesSociais',{attrs:{"selected":_vm.redeSocial.icone},on:{"update:selected":function($event){return _vm.$set(_vm.redeSocial, "icone", $event)}}})],1)]},proxy:true}]),model:{value:(_vm.redeSocial.nome),callback:function ($$v) {_vm.$set(_vm.redeSocial, "nome", $$v)},expression:"redeSocial.nome"}}),_c('v-combobox',{ref:"localBox",attrs:{"items":_vm.locais,"search-input":_vm.buscaLocais,"disabled":_vm.loadingLocal,"rules":_vm.formRules,"label":"Local","item-text":"descricao","item-value":"id","clearable":"","return-object":"","dense":"","multiple":"","chips":""},on:{"update:searchInput":function($event){_vm.buscaLocais=$event},"update:search-input":function($event){_vm.buscaLocais=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addLocais.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(!_vm.buscaLocais)?_c('v-list-item',[_vm._v(" nenhum local encontrado ")]):_c('v-list-item',{staticClass:"my-n2 py-1",on:{"click":function($event){$event.stopPropagation();return _vm.addLocais.apply(null, arguments)}}},[_c('span',{staticClass:"caption"},[_vm._v("Clique para adicionar ")]),_c('v-sheet',{staticClass:"px-3 py-1 ml-2 text-body-2",attrs:{"label":"","color":"accent","rounded":""}},[_vm._v(" "+_vm._s(_vm.buscaLocais)+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(item.edit)?_c('v-text-field',{attrs:{"append-icon":"mdi-content-save"},on:{"click":function($event){$event.stopPropagation();},"click:append":function($event){return _vm.updateLocais(item)}},model:{value:(item.descricao),callback:function ($$v) {_vm.$set(item, "descricao", $$v)},expression:"item.descricao"}}):[_vm._v(" "+_vm._s(item.descricao)+" ")],_c('v-spacer'),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();item.edit = !item.edit}}},[(item.edit)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.descricao)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" mdi-close ")])],1):_vm._e()]}}]),model:{value:(_vm.selectedLocais),callback:function ($$v) {_vm.selectedLocais=$$v},expression:"selectedLocais"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":!_vm.validForm,"color":"secondary"},on:{"click":function($event){_vm.add ? _vm.addRedeSocial() : _vm.editRedeSocial()}}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }