<template>
  <v-dialog
    v-model="dialogEditRedeSocial"
    @click:outside="$emit('update:dialogEditRedeSocial', false)"
    @keydown.esc="$emit('update:dialogEditRedeSocial', false)"
    scrollable
    width="500"
  >
    <v-card tile>
      <v-card-title class="pa-4 font-weight-bold accent">
        {{ add ? "Adicionar" : "Editar" }} Rede Social
        <v-spacer></v-spacer>
        <v-btn
          x-small
          icon
          @click="$emit('update:dialogEditRedeSocial', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="validForm" lazy-validation>
        <v-card-text class="pt-5">
          <v-text-field
            class="mb-6"
            v-model="redeSocial.nome"
            label="Rede Social"
            dense
            :rules="formRules"
            required
          >
            <template v-slot:prepend-inner>
              <v-menu bottom right offset-x :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    size="30"
                    v-bind="attrs"
                    v-on="on"
                    class="pa-1 mb-2 mr-1"
                    tile
                  >
                    <v-img
                      v-if="redeSocial.icone"
                      :src="redeSocial.icone"
                      max-width="30px"
                      max-height="30px"
                    >
                    </v-img>
                    <v-icon v-else>mdi-image-plus</v-icon>
                  </v-avatar>
                </template>

                <IconesRedesSociais :selected.sync="redeSocial.icone" />
              </v-menu>
            </template>
          </v-text-field>

          <v-combobox
            v-model="selectedLocais"
            ref="localBox"
            :items="locais"
            :search-input.sync="buscaLocais"
            :disabled="loadingLocal"
            :rules="formRules"
            label="Local"
            item-text="descricao"
            item-value="id"
            clearable
            return-object
            dense
            multiple
            chips
            @keydown.enter="addLocais"
          >
            <template v-slot:no-data>
              <!--Se nao tiver nenhu dado no componente -->
              <v-list-item v-if="!buscaLocais">
                nenhum local encontrado
              </v-list-item>
              <v-list-item v-else @click.stop="addLocais" class="my-n2 py-1">
                <span class="caption">Clique para adicionar </span>
                <v-sheet
                  label
                  color="accent"
                  rounded=""
                  class="px-3 py-1 ml-2 text-body-2"
                >
                  {{ buscaLocais }}
                </v-sheet>
              </v-list-item>
            </template>

            <template v-slot:item="{ index, item }">
              <v-text-field
                v-model="item.descricao"
                v-if="item.edit"
                @click.stop
                append-icon="mdi-content-save"
                @click:append="updateLocais(item)"
              ></v-text-field>
              <template v-else>
                {{ item.descricao }}
              </template>

              <v-spacer></v-spacer>
              <v-list-item-action>
                <v-btn icon small @click.stop.prevent="item.edit = !item.edit">
                  <v-icon v-if="item.edit" small>mdi-close</v-icon>
                  <v-icon v-else small>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </template>

            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
                label
                small
                class="ma-1"
              >
                <span class="mr-2">
                  {{ item.descricao }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                  mdi-close
                </v-icon>
              </v-chip>
            </template>
          </v-combobox>
        </v-card-text>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          @click="add ? addRedeSocial() : editRedeSocial()"
          :disabled="!validForm"
          color="secondary"
          class="white--text"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  fetchLocaisRedesSociais,
  putLocalRedeSocial,
  postLocalRedeSocial,
} from "@/api/colaborador/midias_sociais_locais_redes_sociais.js";
import { inputRequired } from "@/helpers/utils.js";
import {
  putRedeSocial,
  postRedeSocial,
} from "@/api/colaborador/midias_sociais_redes_sociais.js";

export default {
  name: "AddEditRedeSocial",

  props: {
    dialogEditRedeSocial: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },
    add: {
      type: Boolean,
    },
  },
  components: {
    IconesRedesSociais: () => import("./IconesRedesSociais.vue"),
  },

  data() {
    return {
      loadingLocal: false,
      locais: [],
      local: { status: 1 },
      selectedLocais: [],
      buscaLocais: "",
      redeSocial: {},
      loading: false,
      validForm: true,
      formRules: [inputRequired],
    };
  },

  watch: {},

  methods: {
    getLocais() {
      this.loadingLocal = true;
      return fetchLocaisRedesSociais()
        .then((response) => {
          const locais = response.map((item) => {
            item.edit = false;
            return item;
          });

          this.locais = locais;
          this.loadingLocal = false;
        })
        .catch(() => {
          this.loadingLocal = false;
        });
    },

    addLocais() {
      let local_existe = this.locais.find(
        (item) => item.descricao === this.buscaLocais
      );

      if (local_existe) {
        this.$toast.error("Local ja existe, selecione nas lista!");
        this.buscaLocais = null;
        return;
      }

      let local = { status: 1 };
      local.descricao = this.buscaLocais;
      this.buscaLocais = null;

      postLocalRedeSocial(local)
        .then((response) => {
          if (response.status === 201) {
            const local = response.data.added;
            local.edit = false;
            this.getLocais();
            this.selectedLocais.push(local);
            this.$toast.success("Local da rede social criado com sucesso!");
            this.$refs.localBox.isFocused = false;
          }
        })
        .catch(() => {});
    },

    updateLocais(item) {
      let local = { status: 1 };
      local.id = item.id;
      local.descricao = item.descricao;

      putLocalRedeSocial(local.id, local)
        .then((response) => {
          if (response.status === 201) {
            item.edit = false;
            this.$toast.success("Local rede social atualizado com sucesso!");
            this.getLocais();
          }
        })
        .catch((error) => {
          console.log(error);
          // this.loadingLocal = false;
        });
    },

    addRedeSocial() {
      if (this.$refs.form.validate()) {
        let redeSocial = {};
        redeSocial.nome = this.redeSocial.nome;
        redeSocial.icone = this.redeSocial.icone;
        redeSocial.locais = this.selectedLocais.map((item) => item.id);

        postRedeSocial(redeSocial)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("rede social criado com sucesso!");
              this.$emit("fetch");
              this.$emit("update:dialogEditRedeSocial", false);
            }
          })
          .catch((error) => {
            if (error.response.status === 409) {
              if (error.response.data.result === "already existis") {
                this.$toast.error("Rede social ja existe!");
              }
            }
          });
      }
    },

    editRedeSocial() {
      if (this.$refs.form.validate()) {
        let redeSocial = {};
        redeSocial.id = this.redeSocial.id;
        redeSocial.nome = this.redeSocial.nome;
        redeSocial.icone = this.redeSocial.icone;
        redeSocial.locais = this.selectedLocais.map((item) => item.id);

        putRedeSocial(redeSocial.id, redeSocial)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Rede social atualizado com sucesso!");
              this.$emit("fetch");
              this.$emit("update:dialogEditRedeSocial", false);
            }
          })
          .catch((error) => {
            if (error.response.status === 409) {
              if (error.response.data.result === "already existis") {
                this.$toast.error("Rede social ja existe!");
              }
            }
          });
      }
    },
  },

  async mounted() {
    await this.getLocais();

    if (this.item && this.add == false) {
      this.redeSocial = { ...this.item };

      let locais_id = this.item.locais.map((item) => {
        return item.id;
      });

      this.selectedLocais = this.locais.filter((item) =>
        locais_id.includes(item.id)
      );
    }
  },
};
</script>

<style lang="scss"></style>
